// import logo from "./logo.svg";
// import "./App.scss";
// import { Route, Routes } from "react-router-dom";
// import MainLayout from "./Layout/MainLayout";
// import ChestShop from "./Views/ChestShop/ChestShop";
// import Marketplace from "./Views/Marketplace/Marketplace";
// import Training from "./Views/Training/Training";
// import StakeFarm from "./Views/StakeFarm/StakeFarm";
// import Fusion from "./Views/Fusion/Fusion";
// import Login from "./Layout/Login";
// import MyNFTS from "./Views/MyNFTS/MyNFTS";

import React, { Suspense } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import routes from './routes/routes';
import './App.scss';


function App() {
  return (
    <>
    <BrowserRouter>
      	<Suspense fallback={<div className="d-flex justify-content-center align-items-center">Loading...</div>}>
			<Routes>
				{routes.map(({exact, path, element}, key) => 
					{
						return  <Route
							exact={exact}
							path={path}
							element={element}
							key={key} />;
					}
				)}
			</Routes>
		</Suspense>
    </BrowserRouter>
      {/*  <Routes>
        <Route activeClassName="active" path="/" exact element={<Login />} />
        <Route activeClassName="active" path="/mynfts" element={<MyNFTS />} />
        <Route activeClassName="active" path="/mainlayout" element={<MainLayout />} />
        <Route activeClassName="active" path="/chestshop" element={<ChestShop />} />
        <Route activeClassName="active" path="/marketplace" element={<Marketplace />} />
        <Route activeClassName="active" path="/training" element={<Training />} />
        <Route activeClassName="active" path="/stakefarm" element={<StakeFarm />} />
        <Route activeClassName="active" path="/fusion" element={<Fusion />} />
        <Route path='/guides' element={<Guides/>} />
        <Route path='/user-support' element={<UserSupport/>} />
        <Route path='/referral-program' element={<ReferralProgram/>} />
        <Route path='/bug-bounty' element={<BugBounty/>} /> 
      </Routes>*/}
    </>
  );
}

export default App;
