import { lazy } from 'react';

// const Login = lazy(() => import('../Layout/Login'));
const MyNFTS = lazy(() => import('../Views/MyNFTS/MyNFTS'));
const ChestShop = lazy(() => import('../Views/ChestShop/ChestShop'));
const Marketplace = lazy(() => import('../Views/Marketplace/Marketplace'));
const Training = lazy(() => import('../Views/Training/Training'));
const StakeFarm = lazy(() => import('../Views/StakeFarm/StakeFarm'));
const Fusion = lazy(() => import('../Views/Fusion/Fusion'));

const routes = [
    // { path: `/`, exact: true, name: "Login", element: <Login /> },
    { path: `/`, exact: true, name: "MyNFTS", element: <MyNFTS /> },
    { path: `mynfts`, exact: true, name: "MyNFTS", element: <MyNFTS /> },
    { path: `chestshop`, exact: true, name: "ChestShop", element: <ChestShop /> },
    { path: `marketplace`, exact: true, name: "Marketplace", element: <Marketplace /> },
    { path: `training`, exact: true, name: "Training", element: <Training /> },
    { path: `stakefarm`, exact: true, name: "StakeFarm", element: <StakeFarm /> },
    { path: `fusion`, exact: true, name: "Fusion", element: <Fusion /> },
];

export default routes;